// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")

import '../stylesheets/application'
import '@fortawesome/fontawesome-free/js/all.js'

import './bootstrap_custom.js'
import 'bootstrap-select/dist/css/bootstrap-select.css'
import 'bootstrap-select/dist/js/bootstrap-select.min.js'
import 'bootstrap-select/dist/js/i18n/defaults-ru_RU.min.js'
import 'jquery-validation/dist/jquery.validate.min.js'
import './local_time_ru.js'

import Clipboard from 'clipboard/dist/clipboard.min';
// import AutoNumeric from 'autonumeric/dist/autoNumeric.min';

$.resizeWindow = function() {

    // BX24.fitWindow();

    var currentSize = BX24.getScrollSize();
    var bodyHeight = document.querySelector('body').scrollHeight;
    var width = currentSize.scrollWidth;
    var height = currentSize.scrollHeight;
    BX24.resizeWindow(width, bodyHeight + 150);

    // BX24.scrollParentWindow(0);
    document.getElementById("main").scrollIntoView(true); // прокрутка к началу
}


var ready;
ready = function() {  

    new Clipboard('.clipboard-trigger');

    // AutoNumeric.multiple('.currency', {
    //     allowDecimalPadding: false,
    //     decimalCharacter : ',',
    //     digitGroupSeparator : ' ',
    // });

    // $('.clear-selectpicker').click(function (event) {
    //   $('.' + $(this).data('target')).selectpicker('deselectAll');
    //   event.preventDefault();
    // });

    // $('.reset-selectpicker').click(function (event) {
    //   $('.' + $(this).data('target')).selectpicker('val', 0);
    //   event.preventDefault();
    // });

    // $('.clear-input').click(function (event) {
    //   $('.' + $(this).data('target')).val('')
    //   event.preventDefault();
    // });

    // $('select').selectpicker();
    
    $('#newTaskModal').on('shown.bs.modal', function () {
      $('#task_TITLE').trigger('focus')
    })

    $('#newDealModal').on('shown.bs.modal', function () {
      $('#deal_TITLE').trigger('focus')
    })

    $('#newContactModal').on('shown.bs.modal', function () {
      $('#contact_LAST_NAME').trigger('focus')
    })

    $("#filter_form").validate({
      rules: {
        'filter_name': {
          // required: true,
          required: function(element) {
              return $(document.activeElement).attr('name') == 'save-filter'
          },
          minlength: 1,
          normalizer: function( value ) {
            return $.trim( value );
          }
        }
      },
      messages: {
        'filter_name': "Обязательно для заполнения",
      },
      errorElement: 'div',
      errorLabelContainer: '.errorTxt',
      errorClass: "text-danger"
    });

    // --- отключаем ссылку "назад" если история браузера пустая
    if ( history.length <= 1 ) {
      $('#history-back-link').addClass('disabled')
    }

    // --- тень для карточки закупки
    $( ".purchase-card-active" ).hover(
      function() {
        $(this).addClass('shadow-lg');
      }, function() {
        $(this).removeClass('shadow-lg');
      }
    );

     $('#detailFilter').on('hide.bs.collapse', function () {
       $.resizeWindow();
       $('#detailFilterLink').html('<i class="fas fa-chevron-down"></i> Подробный фильтр');
     })

     $('#detailFilter').on('show.bs.collapse', function () {
       $.resizeWindow();
       $('#detailFilterLink').html('<i class="fas fa-chevron-up"></i> Свернуть фильтр');
     })

    $('a[data-toggle="tab"]').on('shown.bs.tab', function (event) {
        $.resizeWindow();
    })

    $.resizeWindow();
};

$(document).ready(ready)
$(document).on('page:load', ready)

import LocalTime from "local-time"

LocalTime.config.i18n["ru"] = {
      date: {
          dayNames: [
            "воскресенье",
            "понедельник",
            "вторник",
            "среда",
            "четверг",
            "пятница",
            "суббота",
          ],
          abbrDayNames: [
            "Вс",
            "Пн",
            "Вт",
            "Ср",
            "Чт",
            "Пт",
            "Сб",
          ],
          monthNames: [
            "января",
            "февраля",
            "марта",
            "апреля",
            "мая",
            "июня",
            "июля",
            "августа",
            "сентября",
            "октября",
            "ноября",
            "декабря",
          ],
        abbrMonthNames: [
            "янв.",
            "февр.",
            "марта",
            "апр.",
            "мая",
            "июня",
            "июля",
            "авг.",
            "сент.",
            "окт.",
            "нояб.",
            "дек.",
        ],
        yesterday: "вчера",
        today: "сегодня",
        tomorrow: "завтра",
        on: "{date}",
        formats: {
            default: "%b %e, %Y",
            thisYear: "%b %e",
        },
      },
      time: {
        am: "am",
        pm: "pm",
        singular: "в {time}",
        singularAn: "в {time}",
        elapsed: "{time} назад",
        second: "секунда",
        seconds: "секунд",
        minute: "минута",
        minutes: "минут",
        hour: "час",
        hours: "часов",
        formats: {
          default: "%l:%M%P",
        }
      },
      datetime: {
        at: "{date} в {time}",
        formats: {
          default: "%B %e, %Y в %l:%M%P %Z"
        }
      },
}

LocalTime.config.locale = "ru"

LocalTime.start()


